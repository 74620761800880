<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                     <ValidationProvider name="Agent Type" vid='agent_type' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="agent_type"
                            slot-scope="{ valid, errors }"
                            >
                            <b-form-radio-group
                                v-model="agent.agent_type"
                                :options="options"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    <b-row>
                        <b-col lg="6" sm="6" v-if="agent.agent_type == 1">
                            <ValidationProvider name="Agent Id" vid="agent_id" rules="required|numeric">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="agent_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.agent_id')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="agent_id"
                                        v-model="agent.agent_id"
                                        :placeholder="$t('fertilizerConfig.agent.agent_id')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Port"  vid="Port" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="Port"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.port')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="agent.port_id"
                                        :options="portList"
                                        id="Port"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value=null>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Company Agent Name"  vid="company_agent_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="company_agent_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.company_agent_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="company_agent_name"
                                        v-model="agent.company_agent_name"
                                        :placeholder="$t('fertilizerConfig.agent.company_agent_name')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                             <ValidationProvider name="Company Agent Name Bn"  vid="company_agent_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="company_agent_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.company_agent_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="company_agent_name_bn"
                                        v-model="agent.company_agent_name_bn"
                                        :placeholder="$t('fertilizerConfig.agent.company_agent_name_bn')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Address Name"  vid="address">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.address')}}
                                    </template>
                                    <b-form-textarea
                                        id="address"
                                        v-model="agent.address"
                                        :placeholder="$t('fertilizerConfig.agent.address')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Address Name Bn"  vid="address_bn">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="address_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.address_bn')}}
                                    </template>
                                    <b-form-textarea
                                        id="address_bn"
                                        v-model="agent.address_bn"
                                        :placeholder="$t('fertilizerConfig.agent.address_bn')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Tin No"  vid="tin_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="tin_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.tin_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="tin_no"
                                        v-model="agent.tin_no"
                                        :placeholder="$t('fertilizerConfig.agent.tin_no')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Bin No"  vid="bin_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="bin_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.bin_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="bin_no"
                                        v-model="agent.bin_no"
                                        :placeholder="$t('fertilizerConfig.agent.bin_no')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Ceo Name"  vid="ceo_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ceo_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.ceo_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="ceo_name"
                                        v-model="agent.ceo_name"
                                        :placeholder="$t('fertilizerConfig.agent.ceo_name')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Ceo Name Bn"  vid="ceo_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ceo_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.ceo_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="ceo_name_bn"
                                        v-model="agent.ceo_name_bn"
                                        :placeholder="$t('fertilizerConfig.agent.ceo_name_bn')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Ceo NID"  vid="ceo_nid" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ceo_nid"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.ceo_nid')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="ceo_nid"
                                        v-model="agent.ceo_nid"
                                        :placeholder="$t('fertilizerConfig.agent.ceo_nid')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Ceo NID Attachment"  vid="ceo_nid_attachment">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="ceo_nid_attachment"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.ceo_nid_attachment')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-file
                                        id="ceo_nid_attachment"
                                        v-on:change="onFileChange"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Contact Person Name"  vid="contact_person_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="contact_person_name"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.contact_person_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="contact_person_name"
                                        v-model="agent.contact_person_name"
                                        :placeholder="$t('fertilizerConfig.agent.contact_person_name')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Contact Person Name Bn"  vid="contact_person_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="contact_person_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.contact_person_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="contact_person_name_bn"
                                        v-model="agent.contact_person_name_bn"
                                        :placeholder="$t('fertilizerConfig.agent.contact_person_name_bn')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Contact Person Mobile"  vid="contact_person_mobile_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="contact_person_mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.contact_person_mobile_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="contact_person_mobile_no"
                                        v-model="agent.contact_person_mobile_no"
                                        :placeholder="$t('fertilizerConfig.agent.contact_person_mobile_no')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Email"  vid="email">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="email"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.agent.email')}}
                                    </template>
                                    <b-form-input
                                        id="email"
                                        v-model="agent.email"
                                        :placeholder="$t('fertilizerConfig.agent.email')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { agentStore, agentUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getagentData()
      this.agent = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      agent: {
        id: '',
        agent_type: 1,
        company_agent_name: '',
        company_agent_name_bn: '',
        address: '',
        address_bn: '',
        ceo_nid_attachment: '',
        port_id: null
      },
      ports: [
        {}
      ],
      options: [
        { item: 1, name: this.$t('fertilizerConfig.cnfAgent') },
        { item: 2, name: this.$t('fertilizerConfig.transportAgent') }
      ],
      attachmentDemo: ''
    }
  },
  computed: {
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    }
  },
  methods: {
    onFileChange (e) {
      this.attachmentDemo = e.target.files[0]
    },
    getagentData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.agent).map(key => {
        if (key === 'ceo_nid_attachment') {
            if (this.attachmentDemo) {
                formData.append(key, this.attachmentDemo)
            } else {
                formData.append(key, this.agent[key])
            }
        } else {
          formData.append(key, this.agent[key])
        }
      })
      if (this.agent.id) {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, `${agentUpdate}/${this.id}`, formData)
      } else {
        formData.append('created_by', 1)
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, agentStore, formData, config)
        // result = await RestApi.postData(seedFertilizerServiceBaseUrl, 'fertilizer/config/agents/store', formData, config)
      }
      const loadinState = { loading: false, listReload: false }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
