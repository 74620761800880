<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col lg="3" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('globalTrans.type')"
                            label-for="agent_type"
                        >
                            <b-form-select
                                plain
                                v-model="search.agent_type"
                                :options="options"
                                id="agent_type"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="3" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('fertilizerConfig.agent.port')"
                            label-for="port"
                        >
                            <b-form-select
                                plain
                                v-model="search.port_id"
                                :options="portList"
                                id="Port"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="4" sm="12">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('fertilizerConfig.agent.company_agent_name')"
                            label-for="company_agent_name"
                        >
                            <v-select name="godown_Info"
                                v-model="search.company_agent_name"
                                label="text"
                                :options= agentData
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-sm-2 mt-2">
                        <br/>
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerConfig.agentList')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <div class="status_wrapper status_active" v-if="data.item.status == 1" title="active">
                                                <b-badge variant="success">Active</b-badge>
                                            </div>
                                            <div class="status_wrapper status_inactive" v-else title="inactive">
                                                <b-badge variant="dark">Inactive</b-badge>
                                            </div>
                                        </template>
                                        <template v-slot:cell(agent_type)="data">
                                            <span class="badge badge-info" v-if="data.item.agent_type == 1">{{$t('fertilizerConfig.cnfAgent')}}</span>
                                            <span class="badge badge-warning" v-else>{{$t('fertilizerConfig.transportAgent')}}</span>
                                        </template>
                                        <template v-slot:cell(contact_person_mobile_no)="data">
                                            {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.contact_person_mobile_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="edit(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Agent Update" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Click to Deactive" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Active" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <FormView :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import FormView from './FormView'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { agentList, agentToggleStatus, agentSearch } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        FormView
    },
    data () {
        return {
            search: {
                id: 0,
                port_id: 0,
                agent_type: 0,
                company_agent_name: null
            },
            cnfAgentList: [],
            options: [
                { value: 1, text: this.$t('fertilizerConfig.cnfAgent') },
                { value: 2, text: this.$t('fertilizerConfig.transportAgent') }
            ]
        }
    },
    watch: {
        'search.port_id': function (newVal, oldVal) {
            this.getAgent()
        },
        'search.agent_type': function (newVal, oldVal) {
            this.getAgent()
        }
    },
    computed: {
        agentData: function () {
            const listObject = this.cnfAgentList
            const tmpList = listObject.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
            return tmpList
        },
        portList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('fertilizerConfig.agentCreate') : this.$t('fertilizerConfig.agentUpdate')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.agent.company_agent_name'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.agent.ceo_name'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.agent.contact_person_name'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.agent.contact_person_mobile_no'), class: 'text-center' },
                { label: this.$t('globalTrans.type'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'company_agent_name_bn' },
                { key: 'ceo_name_bn' },
                { key: 'contact_person_name_bn' },
                { key: 'contact_person_mobile_no' },
                { key: 'agent_type' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'company_agent_name' },
                { key: 'ceo_name' },
                { key: 'contact_person_name' },
                { key: 'contact_person_mobile_no' },
                { key: 'agent_type' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        getAgent () {
            if(this.search.port_id || this.search.agent_type) {
                RestApi.getData(seedFertilizerServiceBaseUrl, agentSearch, { port_id: this.search.port_id, agent_type: this.search.agent_type }).then(response => {
                    this.cnfAgentList = response.data
                })
            }
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, agentToggleStatus, item, 'seeds_fertilizer', 'cnfAgentList')
            this.changeStatus(seedFertilizerServiceBaseUrl, agentToggleStatus, item, 'seeds_fertilizer', 'transportAgentList')
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                commodity_group_name: '',
                commodity_group_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            this.search.id = null
            if (this.search.company_agent_name) {
                this.search.id = this.search.company_agent_name.value
            }
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, agentList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
<style>
    .w-10{
        width: 14%;
    }
</style>
