export const testApi = '/test'

export const agentList = '/fertilizer/config/agents/list'
export const agentSearch = '/fertilizer/config/agents/search'
export const agentStore = '/fertilizer/config/agents/store'
export const agentUpdate = '/fertilizer/config/agents/update'
export const agentToggleStatus = '/fertilizer/config/agents/toggle-status'
export const agentDestroy = '/fertilizer/config/agents/destroy'
// Port Zone Setup Api Routes
export const portZoneSetupList = 'fertilizer/config/port-zone-setup/list'
export const portZoneSetupStore = 'fertilizer/config/port-zone-setup/store'
export const portZoneSetupUpdate = 'fertilizer/config/port-zone-setup/update'
export const portZoneSetupToggleStatus = 'fertilizer/config/port-zone-setup/toggle-status'
export const portZoneSetupDestroy = 'fertilizer/config/port-zone-setup/destroy'

export const salesCenterList = '/fertilizer/config/sales-center/list'
export const salesCenterStore = '/fertilizer/config/sales-center/store'
export const salesCenterUpdate = '/fertilizer/config/sales-center/update'
export const salesCenterToggleStatus = '/fertilizer/config/sales-center/toggle-status'
export const salesCenterDestroy = '/fertilizer/config/sales-center/destroy'

export const transportRentList = '/fertilizer/config/transport-rent/list'
export const transportRentStore = '/fertilizer/config/transport-rent/store'
export const transportRentUpdate = '/fertilizer/config/transport-rent/update'
export const transportRentToggleStatus = '/fertilizer/config/transport-rent/toggle-status'
export const transportRentDestroy = '/fertilizer/config/transport-rent/destroy'

export const portInfoSetupList = 'fertilizer/config/port-info/list'
export const portInfoSetupStore = 'fertilizer/config/port-info/store'
export const portInfoSetupUpdate = 'fertilizer/config/port-info/update'
export const portInfoSetupToggleStatus = 'fertilizer/config/port-info/toggle-status'
export const portInfoSetupDestroy = 'fertilizer/config/port-info/destroy'

export const importCountryList = 'fertilizer/config/import-country/list'
export const importCountryStore = 'fertilizer/config/import-country/store'
export const importCountryUpdate = 'fertilizer/config/import-country/update'
export const importCountryToggleStatus = 'fertilizer/config/import-country/toggle-status'
export const importCountryDestroy = 'fertilizer/config/import-country/destroy'

export const warehouseUserList = '/fertilizer/config/warehouse-user/list'
export const warehouseUserSelectList = '/fertilizer/config/warehouse-user/select-list'
export const warehouseUserStore = '/fertilizer/config/warehouse-user/store'
export const warehouseUserUpdate = '/fertilizer/config/warehouse-user/update'
export const warehouseUserToggleStatus = '/fertilizer/config/warehouse-user/toggle-status'
export const warehouseUserDestroy = '/fertilizer/config/warehouse-user/destroy'

export const godownInformationList = '/fertilizer/config/godown-information/list'
export const godownInformationStore = '/fertilizer/config/godown-information/store'
export const godownInformationUpdate = '/fertilizer/config/godown-information/update'
export const godownInformationToggleStatus = '/fertilizer/config/godown-information/toggle-status'
export const godownInformationDestroy = '/fertilizer/config/godown-information/destroy'

export const fertilizerTypeList = '/fertilizer/config/fertilizer-type/list'
export const fertilizerTypeStore = '/fertilizer/config/fertilizer-type/store'
export const fertilizerTypeUpdate = '/fertilizer/config/fertilizer-type/update'
export const fertilizerTypeToggleStatus = '/fertilizer/config/fertilizer-type/toggle-status'
export const fertilizerTypeDestroy = '/fertilizer/config/fertilizer-type/destroy'

export const fertilizerNameList = '/fertilizer/config/fertilizer-name/list'
export const fertilizerNameStore = '/fertilizer/config/fertilizer-name/store'
export const fertilizerNameUpdate = '/fertilizer/config/fertilizer-name/update'
export const fertilizerNameToggleStatus = '/fertilizer/config/fertilizer-name/toggle-status'
export const fertilizerNameDestroy = '/fertilizer/config/fertilizer-name/destroy'

export const dealerSetupList = '/fertilizer/config/dealer-setup/list'
export const dealerSetupStore = '/fertilizer/config/dealer-setup/store'
export const dealerSetupUpdate = '/fertilizer/config/dealer-setup/update'
export const dealerSetupToggleStatus = '/fertilizer/config/dealer-setup/toggle-status'
export const dealerSetupDestroy = '/fertilizer/config/dealer-setup/destroy'

// allocation Type api
export const allocationTypeList = '/fertilizer/config/allocation-type/list'
export const allocationTypeStore = '/fertilizer/config/allocation-type/store'
export const allocationTypeUpdate = '/fertilizer/config/allocation-type/update'
export const allocationTypeToggleStatus = '/fertilizer/config/allocation-type/toggle-status'
export const allocationTypeDestroy = '/fertilizer/config/allocation-type/destroy'

// Fertilizer Price api
export const fertilizerPriceList = '/fertilizer/config/fertilizer-price/list'
export const fertilizerPriceStore = '/fertilizer/config/fertilizer-price/store'
export const fertilizerPriceUpdate = '/fertilizer/config/fertilizer-price/update'
export const fertilizerPriceToggleStatus = '/fertilizer/config/fertilizer-price/toggle-status'
export const fertilizerPriceDestroy = '/fertilizer/config/fertilizer-price/destroy'

// Organization api
export const organizationList = '/fertilizer/config/organization/list'
export const organizationStore = '/fertilizer/config/organization/store'
export const organizationUpdate = '/fertilizer/config/organization/update'
export const organizationToggleStatus = '/fertilizer/config/organization/toggle-status'
export const organizationDestroy = '/fertilizer/config/organization/destroy'

// FOB-CRF Procurement
const fobCfrProcurementPrefix = '/fertilizer/procurements/fob-procurements'
export const fobCfrProcurementList = fobCfrProcurementPrefix + '/list'
export const fobCfrProcurementStore = fobCfrProcurementPrefix + '/store'
export const fobCfrProcurementUpdate = fobCfrProcurementPrefix + '/update'
export const fobCfrProcurementToggle = fobCfrProcurementPrefix + '/toggle-status'

export const reportHeadingList = '/fertilizer/config/report-heading/list'
export const reportHeadingStore = '/fertilizer/config/report-heading/store'
export const reportHeadingUpdate = '/fertilizer/config/report-heading/update'
export const reportToggleStatus = '/fertilizer/config/report-heading/toggle-status'
export const reportHeaderDestroy = '/fertilizer/config/report-heading/destroy'

export const openingBlanceList = '/fertilizer/config/opening-balance/list'
export const openingBlanceStore = '/fertilizer/config/opening-balance/store'

// dashboard route
export const salesInfo = '/fertilizer/dashboard/sales-info'
export const importStatus = '/fertilizer/dashboard/import-status'
export const priceInfo = '/fertilizer/dashboard/price-info'
export const currentStockReport = 'fertilizer/reports/movement-report/current-stock-report'
